.row.tabs-row {
    margin: 0;
    .stop-tab  {
        padding: 0.25rem 1.25rem;
        margin: 0 0 -1px;

        width: max-content;

        border: 1px solid  gray;
        border-radius: 5px 5px 0 0 ;
        border-bottom-color: var(--bs-body-bg);

        font-size: 1rem;
        color: black !important;


        text-decoration: none;
        text-align: center;

        &:not(.selected-tab) {
            border-bottom-color: gray;
        }


    }
}

.tabs-row + .tabs-body {
    border: 1px solid gray;
    border-radius: 0 5px 5px 5px;
    padding: 0.25rem 0.5rem;

    .filters {
        .chosen-container.chosen-container-multi {
            width: max-content !important;
            .chosen-choices {
                max-width: 35rem;
                width: max-content;
                min-width: 15rem;
            }
        }
    }

    table {
        margin: 0.25rem 0;
        td, th {
            text-align: left;
            padding: 0.25rem 0 0.25rem 1rem;
            &[field="stop_cause"] select{
                width: 80%;
            }
        }
        tbody {
            border: 1px solid rgba(0, 0, 0, 0.3);;
            border-left: none;
            border-right: none;
        }
    }
    nav .pagination {
        //justify-content: flex-end;
        margin-bottom: 0;
        .page-item {
            border: none;
            background: none;
            margin: .05rem;
            & > a, & > span {
                border: 1px solid transparent;
                background: transparent;
                border-radius: 2px;
                color: #111;
                transition: none;
                &:hover {
                    color: white;
                    border: 1px solid #111;
                    background: linear-gradient(to bottom, #585858 0%, #111 100%);
                }
            }
            &.disabled {
                color: #666;
            }

            &.active span:not(:hover) {
                border: 1px solid rgba(0, 0, 0, 0.3);
                background: linear-gradient(to bottom, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
            }
        }
    }
}
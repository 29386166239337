// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Vue
@import "monitorization";
@import "oee_graph";
@import "priority_matrix";
@import "reference_group_edit";

//General
@import "orders";
@import "production_line";
@import "stop";

//@import "control_produccion";

// Custom
@import "../css/custom.css";
@import "custom";

//Variable
@import "../css/variables.css";

//Pepe
@import "operation_group_index";

// Vue
//@import '../../node_modules/bootstrap/scss/bootstrap.scss';
//@import '../../node_modules/bootstrap-vue/src/index.scss';
//@import "../css/vue.css";


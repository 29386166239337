.reference-group-edit-wrapper{
    @mixin section-titles {
        font-size: 1.4rem;
        font-weight: 500;
    }
    .relations-wrapper {
        .relations-header {
            display: inline-flex;
            width: 100%;
            .title {
                @include section-titles;
                margin: 0 auto 0 0;
            }
            .button {
                float: right;
            }
        }

        .relation-line-wrapper {
            display: inline-flex;
            align-items: center;
            width: 100%;
            border: none;

            & > td {
                margin: 0.25rem;
                border: none;
            }

            .reference-group-descrip {
                width: 17.5rem;
                min-width: 17.5rem;

                h5 {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .reference-selector {
                min-width: 10%;
                width: 10%;
                & > select {
                    max-width: 100%;
                    width: 100%;
                }
            }
            .reference-list-wrapper {
                border: 1px solid gray;
                border-radius: 5px;
                display: inline-flex;
                width: 65rem;
                min-width: 60rem;
                max-width: 70rem;

                .reference-list {
                    display: flex;
                    flex-wrap: wrap;

                    overflow: auto;
                    max-height: 7rem;

                    .reference-pill-wrapper {

                        background: #e5e5e5;
                        border-radius: 5px;
                        width: max-content;
                        height: max-content;
                        display: inline-flex;
                        margin: 0.125rem;

                        .remove-reference-pill-button {
                            padding: 0.125rem;
                        }
                        .title-wrapper {
                            padding: 0.125rem;
                            .title {
                                white-space: nowrap;
                                max-width: 30ch;
                                text-overflow: ellipsis;
                                overflow: hidden;

                            }
                        }
                    }
                }
            }
        }


    }

}


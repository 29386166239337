.fw-bold{
    font-weight: bold;
}

.go-back-button{
    cursor: pointer;
    //padding: 0.5rem;
    margin: 0;
    svg {
        margin: 0;
    }
    &:hover {
        border-radius: 5px;
        background: rgba(255, 255, 255, 0.5);
    }
}

#navigation-buttons {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 0 0.5rem 0 0;
    & > * {
        padding: 0.25rem;
    }
}

#barra-navegacion {
    .collapse-nav {
        &:hover {
            & > div {
                display: block;
            }
        }
        .collapse-header {
            &:hover + .collapse {
                display: block;
            }
        }
        .collapse {
            &:hover {
                display: block;
            }
        }
    }
}

$notification-separation: 0;
@mixin top {
    top: $notification-separation;
    left: 50%;
    transform: translateX(-50%);
}
@mixin center {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
@mixin bottom {
    top: auto;
    bottom: $notification-separation;
    left: 50%;
    transform: translateX(-50%);
}
@keyframes fade-in  {
    from {opacity: 0}
    to { opacity: 1}
}

body {
    position: relative;
}
#notification-absolute-wrapper {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    pointer-events: none;
    #notification-sticky-wrapper {
        position: sticky;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        pointer-events: none;
        #notification-wrapper {
            position: relative;
            height: 100%;
            width: 100%;
            pointer-events: none;
        }
    }
}

.notification {
    width: max-content;
    height: max-content;
    display: block !important;
    position: absolute !important;
    z-index: 1000;
    margin: 0.5rem 1.5rem;

    transition: 0.2s;

    animation-name: fade-in;
    animation-duration: 0.20s;
    animation-iteration-count: 1;

    left: 50%;
    transform: translateX(-50%);

    max-width: 20rem;

    // Tamaños
    &-s {
        @extend .notification;
        max-width: 15rem;
    }
    &-m {
        @extend .notification;
        max-width: 20rem;
    }
    &-l {
        @extend .notification;
        max-width: 25rem;
    }

    // Posiciones
    &.top {
        @include top;
        &-left  {
            @include top;
            left: $notification-separation;
            transform: none;
        }
        &-right {
            @include top;
            left: auto;
            right: $notification-separation;
            transform: none;
        }
    }
    &.center {
        @include center;
        &-left  {
            @include center;
            left: $notification-separation;
            transform: translateY(-50%);
        }
        &-right {
            @include center;
            left: auto;
            right: $notification-separation;
            transform: translateY(-50%);
        }
    }
    &.bottom {
        @include bottom;
        &-left  {
            @include bottom;
            left: $notification-separation;
            transform: none;
        }
        &-right {
            @include bottom;
            left: auto;
            right: $notification-separation;
            transform: none;
        }
    }


    & > .card-header {
        font-weight: 600;
        font-size: 1.1rem;
    }
    & > .card-body {
        border: 2px solid white;
        font-size: 0.97rem;
    }

    // Tipos
    &.success {
        & > .card-header {
            background: #68ea68;
            color: black;
        }
        & > .card-body {
            border-color: #68ea68;
            background: #ddffdd;
        }

    }
    &.error {
        & > .card-header {
            background: red;
            color: white;
        }
        & > .card-body {
            border-color: red;
            background: #FFE0E0;
        }
    }
    &.warning {}
    &.info {}
    &.question {}
}
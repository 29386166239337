@import url("https://cdn.jsdelivr.net/npm/@mdi/font@6.5.95/css/materialdesignicons.min.css");
@import "https://cdn.jsdelivr.net/npm/@mdi/font@6.5.95/css/materialdesignicons.min.css";
i,svg {
    margin-right:0.5rem;
}

#app:has(.calendar-index-app){
    background-color: #fcf4ecb7;
    min-height: 100vh;
}

header ul{
    list-style: none;
}
.header-reload svg{
    margin:0;
}
/*.header-reload{*/
/*    margin: 1rem;*/
/*    padding: 0.5rem;*/
/*    display: inline-block;*/
/*    cursor: pointer;*/
/*}*/
.header-reload:hover{
    background-color: grey;
    border-radius: .25rem !important;
}

.header-action-buttons{
    background: #27884F;
    color: white !important;
    padding: 0.75rem !important;
    margin: 1rem !important;
    cursor: pointer;
    font-size: 1.5vh !important;
}

.header-action-buttons:hover{
    box-shadow: 0 0 10px 0 white;
    border-radius: .25rem !important;
}

header *{
    z-index: 100;
}
.navegacion{
    padding: 0;
}
ul.navegacion li{
    background-color: white;
}

ul.navegacion{
    width: fit-content;
    min-width: 100%;
}

.navegacion a{
    white-space: nowrap;
    font-weight: 400;
    font-size: 16px;
    color: dimgrey;
    text-decoration: none;
    padding: 1rem;
    display: block;
}
.navegacion a:hover{
    color: black;
    text-decoration: none;
}


.full-header{
    display:inline-flex;
    margin: 0 2rem;
    align-content: center;
    align-items: center;
    width: -moz-available;

}

#barra-navegacion{
    margin-left: 2rem;
    margin-right: 1rem;
}
.collapse-nav{
    position: relative;
    margin: 0 1rem;
}
.collapse-nav:hover{
    color: black;
    background-color: white;
}


.nav-ul{
    display: inline-flex;
    margin: 0;
}

.collapse-header{
    width: fit-content;
    padding: 15px;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    font-size: 16px;
    background-color: inherit;
    display: block;
}
.collapse-header:hover{
    color: black;
}

/*CSS de modales*/
.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
    opacity: .75;
}
.modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
}

.close:hover {
    color: #000;
    text-decoration: none;
}
button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
}
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.orders-list-wrapper {
    .filter {}
    #orders-list{
        thead {
            #table-head-row{
                th {

                }
                th.sort-asc, th.sort-desc {
                    position: relative;
                    //display: inline-flex;
                    align-items: center;
                }

                @mixin sort-common {
                    content: '';
                    display: inline-block;
                    margin: 0 0 0 0.5rem;
                    line-height: 1.6;

                }
                $height: 0.4rem;
                $width: 0.8rem;
                $arrowColor: gray;
                th.sort-asc {
                    &::after {
                        @include sort-common;

                        border-left: calc($width/2) solid transparent;
                        border-right: calc($width/2) solid transparent;

                        border-bottom: $height solid $arrowColor;
                    }
                }
                th.sort-desc {
                    &::after {
                        @include sort-common;

                        border-left: calc($width/2) solid transparent;
                        border-right: calc($width/2) solid transparent;

                        border-top: $height solid $arrowColor;
                    }
                }
            }
        }
        tbody {
            tr {
                @mixin states{
                    color: white;
                    width: max-content;
                    padding: 0.25rem 0.5rem;
                    border-radius: 5px;
                    font-weight: 400;
                    font-size: 0.95rem;
                }
                .pending {
                    @include states;
                    background: #f62d51;
                }
                .in-production {
                    @include states;
                    background: #009efb;
                }
                .finished {
                    @include states;
                    background: #55ce63;
                }
            }
            .dataTableRow {
                cursor: pointer;
                &:hover {
                    background: #f5e4c4;
                    font-weight: 500;
                }
            }
            .order-childs {
                .packaging-select + .chosen-container {
                    width: 100% !important;
                    max-width: 100%;
                }
            }
        }
    }
    .orders-list-pagination{
        width: max-content;
        margin: 0.25rem auto 0 auto;
        button {
            width: max-content;
            max-width: 3rem;
            font-size: 1.07rem;
            font-weight: 600;
            margin: 0 0.2rem;
            border-radius: 5px;
            border-color: lightgray;
            &:hover {
                background: #f5e4c4;
                color: dimgray;
            }
        }
    }
}
.production-line-edit {
    .production-line-edit-row {
        .crud-buttons {
            width: 100%;
            & > div {
                margin: 0.5rem 0 0.25rem auto;
                display: inline-flex;
                justify-content: right;
                &  > * {
                    width: max-content;
                    margin: 0 0.25rem;
                }
            }
        }
    }

    .edit-stations {
        .header {
            display: inline-flex;
            button {
                margin: 0 0.75rem;
            }
        }
        .stations {
            margin: 0.25rem;
            .station-button {
                cursor: pointer;

                user-select: none;
                background: lightgray;
                border-radius: 5px;
                padding: 0.25rem 0.5rem;

                &:hover {
                    background: dimgray;
                    color: lightgray;
                }
            }

            @mixin crudButtons {
                display: flex;
                height: 100%;
                .buttons {
                    display: inline-flex;
                    justify-content: right;
                    margin: auto 0 0 auto;
                    & > * {
                        margin: auto 0.25rem 0 0.25rem;
                        width: max-content;
                        height: max-content;
                    }
                }
            }
            .station-edit-modal {

                .chosen-container {
                    width: 100% !important;
                }

                .station-edit-row {
                    .crud-buttons{
                        margin-top: auto;
                        margin-bottom: 1rem;
                        @include crudButtons;
                    }
                }
                .machine-edit-row {
                    .crud-buttons {
                        @include crudButtons;
                    }
                }
                .new-machine-row {
                    .crud-buttons {
                        @include crudButtons
                    }
                }
            }
        }
    }
}

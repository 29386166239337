
@keyframes spinner-speen {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

:root {
    --uma-marine: #153B3C;
    --uma-green: #27884F;
}

.card-header {
    &.collapse-card-header {
        &.production-line-header {
            background: #E5E5E5 !important;
            border-radius: 5px;
            margin: 0;
            cursor: pointer;
            font-weight: bolder;
        }
    }
}

.machine-has-data-display {
    color: var(--uma-marine) !important;
    font-weight: 600 !important;
    font-size: 0.8rem;
    text-decoration: none;
}
.active-machine-button {
    border-color: var(--uma-green) !important;
    background: var(--uma-green) !important;
    opacity: 1;
    &:hover {
        border-color: var(--uma-marine) !important;
        background: var(--uma-marine) !important;
    }
}
.production-line-wrapper {
    margin-bottom: 0.5rem !important;
}

.stations-list {
    display: flex;
    .station-wrapper {
        max-width: 25%;
        margin: 0 0.5rem;
        .machines-list{
            .machine-wrapper {
                margin: 0.5rem;
                & > * {
                    display: inline;
                    font-size: 0.8rem !important;
                }
            }
        }

    }
}


.machine-monitorization{
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
    margin-top: 1.5ch;
}

.spinner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 5px;
    z-index: 20;
}
.spinner-background {
    position: relative;
    width: 80%;
    margin: 0 auto 0.5rem auto ;
    height: auto;

    &-vertical-aligned {
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
    }
}


.spinner {
    position: relative;
    width: 80%;
    margin: 0 auto 0.5rem auto ;
    height: auto;

    &:before {
        content: "";
        display: block;
        padding-top: 100%;
    }

    & > .inner-ring, & > .outer-ring, & > .colored-outer-ring {
        position: absolute;
        top: 0;
        border-radius: 50%;
    }
    & > .inner-ring {
        width: 86%;
        height: 86%;
        margin: 7%;
        background: white;
    }
    & > .outer-ring {
        width: 100%;
        height: 100%;
        background: lightgray;
    }
    & > .colored-outer-ring {
        width: 100%;
        height: 100%;
    }

    & > .tracking-dot-wrapper {
        position: absolute;
        height: 50%;
        width: 50%;
        top: 0;
        left: 25%;
        transform-origin: bottom center;
        transform: rotate(0deg);

        animation-name: spinner-speen;
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;

        & > .tracking-dot {
            position: absolute;
            width: 14%;
            height: 14%;
            background: black;
            border-radius: 50%;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    & > .spinner-text-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0%;
        font-weight: bolder;
        font-size: 3.5rem;
        text-align: center;
        & > span {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.graph-wrapper {
    display: flex;
    position: relative;
    width: max-content;
    margin: 0;
    border: 1px solid gray;
    border-radius: 5px;
    overflow: hidden;
    background-color: rgb(228, 228, 222);
}

.kpi-graph-wrapper {
    width: max-content;
    font-size: 0.9rem;
    border-radius: 5px;
    padding: 0.25rem;
    margin: 0.5rem 0;
    & > .kpi-section {
        position: relative;
        border-radius: 5px;
        margin: 0.25rem 0;
        padding: 1rem 1rem 0.2rem 1rem;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    & > .kpi-header {
        padding-top: 0.2rem;
        .kpi--name {
            font-weight: bolder;
        }
        .kpi-stops {
            & > * {
                display: inline;
            }
        }
    }
    & > .kpi-body {
        .kpi-confection-wrapper {
            left: 0.75rem;
            top: 0.5rem;
        }
        .kpi-order-wrapper {
            left: 0.75rem;
            top: 2rem;
        }
        .kpi-delayed {
            width: max-content;
            margin: auto;
            & > * {
                display: inline;
            }
            & > span {
                font-weight: bolder;
                font-size: 0.8rem;
            }
            .kpi--delayed-units {
                font-size: 1.2rem;
            }
        }

    }
    & > .kpi-footer {
        background: var(--uma-marine);
        color: white;
        padding-top: 0.2rem;
        .kpi--operator-name {
            margin-bottom: 0.25rem;
        }

        .kpi-reference-wrapper {
            margin: 0.5rem 0;
            .kpi--reference{
                font-size: 0.8rem;
                font-weight: 500;
            }
        }

        .kpi-shift {
            text-align: right;
            font-size: 0.65rem;
            padding: 0 1ch;
            & > * {
                display: inline;
            }
        }

    }
}

.kpi-label {
    @extend .c-flexer;
    background: var(--uma-green);
    border-radius: 5px;
    color: white;
    font-size: 0.8rem;
    padding: 0.1rem 0.5rem;
    z-index: 1;
}

.footer-lower-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 1ch 0;
}
.machine-working{
    @extend .c-flexer;
    border-radius: 5px;
    background: var(--uma-green);
    color: white;
    padding: 0 1ch;
}
.machine-no-data {
    @extend .c-flexer;
    border-radius: 5px;
    background-color: rgb(153, 153, 153);
    color: white;
    padding: 0 1ch;
}
.machine-micro-stop {
    @extend .c-flexer;
    border-radius: 5px;
    background-color: rgb(255, 209, 81);
    color: black;
    padding: 0 1ch;
}
.machine-stopped {
    @extend .c-flexer;
    border-radius: 5px;
    background-color: rgb(255, 0, 0);
    color: white;
    padding: 0 1ch;
}

.kpi-body-upper-section-container{
    display: flex;
    justify-content: space-between;
    padding: 0 0 1.5ch 0;
    & > * {
        max-width: 50%;
    }
}

.c-flexer {
    display: flex;
    justify-content: center;
    align-items: center;
}

// CSS GRAFICO BASCULA
.scale-graph-wrapper {
    padding: 0.2rem;
    display: flex;
    & > .main-data-wrapper {
        margin: 0 1.5rem 0 1rem;

        & > .order-info {

            & > * {
                white-space: nowrap;
                padding: 0.25rem 0.5rem;
                border-radius: 5px;
                & > span {
                    margin-right: 3rem;
                }
                & > .amount {
                    float: right;
                }
            }

            .end-pallet-units {
                background: orange;
                margin-bottom: 0.25rem;
            }
            .end-order-units {
                background: dodgerblue;
                margin-bottom: 0.25rem;
            }
            .actual-weight {
                background: orange;
            }
        }
        & > .actual-weight {
            position: relative;
            padding: 4rem 0 0 2.5rem;
            & > .state-hand {
                position: absolute;
                margin: 0.5rem;
                top: 0;
                left: 0;
                transform-origin: top left;
                transform: scale(0.75);
            }

            #radial-graph {
                position: relative;
                & > svg {
                    margin: 0;
                }
                #weight {
                    position: absolute;
                    text-align: center;
                    top: 78%;
                    left: 50%;
                    transform: translateX(-50%);
                    font-size: 0.8rem;
                }
                #radial-indicator-wrapper {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 50%;
                    transform: translateY(-50%);
                    transform-origin: left center;
                    & > svg {
                        transform-origin: 17.5% 50%;
                        transform: translate(-17.5%,-17.5%) rotate(5deg);
                        margin: 0;
                    }
                }
            }
        }
    }
    & > .right-data-wrapper {
        text-align: center;

        &  > * {
            padding: 0.25rem 0.5rem;
            border-radius: 5px;
            &:not(:last-child) {
                margin-bottom: 0.25rem;
            }
        }

        .finished-units {
            background: orange;
            position: relative;
            .value {
                font-size: 2.5rem;
                width: 100%;
                font-weight: bolder;
            }
            .format ,
            span:last-child {
                font-weight: 500;
            }
        }

        $hand-modifier: 0.5;
        @mixin total-amounts {
            white-space: nowrap;
            font-size: 1.3rem;
        }
        @mixin hand-size-mod($hand-modifier){
            transform: scale(1.00 - $hand-modifier );
            margin: -(1rem + $hand-modifier)  0;
        }
        .total-ok-weight {
            border: 1px solid green;
            span:first-child {
                font-weight: 500;
            }
            .ok-hand { @include hand-size-mod($hand-modifier); }
            .amount { @include total-amounts; }
        }

        .total-nook-weight {
            border: 1px solid red;
            span:first-child {
                font-weight: 500;
            }
            .nook-hand { @include hand-size-mod($hand-modifier); }
            .amount { @include total-amounts; }
        }
    }
}



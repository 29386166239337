
.oee-view-wrapper {
    height: 100%;
    width: max-content;
    margin: 0 auto;
    position: relative;
}
.oee-view-content {
    display: inline-flex;
}

.oee-graph-header-wrapper {
    font-weight: 400;
    font-size: 1.035rem;
    margin: 0.25rem 0 1.25rem 0;
    display: flex;
    align-items: center;

    & > div {
        &:not(.update-status) {
            width: max-content;
            border-radius: 5px;
            padding: 0.25rem 0.5rem;
            &:first-child {
                margin: 0 0.5rem 0 0;
            }
        }
        &.update-status {
            position: relative;
            margin: 0 0 0 auto;
            img {
                max-width: 17rem;
            }
            .datetime {
                font-size: 1.35rem;
                font-weight: 600;
                position: absolute;
                top: 50%;
                left: auto;
                right: 3rem;
                transform: translate(0, -50%);
                color: white;
            }
        }
    }

    .current-status-wrapper {
        background: #FFC107;
    }
    .actual-reference {
        background: #009EFB;
        color: white;
        &:hover {
            cursor: pointer;
        }
    }
}


$arrow-width: 0.9rem;
$arrow-height: 0.4rem;
$connector-width: 6px;
$connector-height: 4px;
$arrow-color: black;
$arrow-connector-color: black;
.time-bars {
    max-width: 75%;
    min-width: 42rem;
    margin: 1rem 4rem 1rem 0;
    .time-bar {
        margin: 0.75rem 0 0.75rem calc(#{$connector-width} + #{$arrow-width});
        position: relative;
        //border: 1px solid black;
        white-space: nowrap;
        .content {
            width: 100%;
            display: inline-flex;
            .title-wrapper {
                padding: 0.25rem 0.5rem;
                margin: 0 auto 0 0;
            }
            .time-wrapper {
                color: white;
                font-weight: bolder;
                padding: 0.25rem 0.5rem;
            }
        }


        .left-arrow {
            position: absolute;
            top: 50%;
            left: 0%;
            transform: translate(calc(-100% - #{$connector-width}), -50%);
            display: inline-flex;
            .arrow-head {
                position: relative;
                width: 0;
                height: 0;

                border-right: $arrow-width solid $arrow-color;
                border-top: $arrow-height solid transparent;
                border-bottom: $arrow-height solid transparent;

                &:after {
                    position: absolute;
                    content: '';
                    background: $arrow-connector-color;
                    width: $connector-width;
                    height: $connector-height;
                    left: auto;
                    right: -($arrow-width);
                    top: 50%;
                    transform: translate(100%, -50%);
                }
            }
        }
        .right-arrow {
            position: absolute;
            top: 50%;
            left: auto;
            right: 0%;
            transform: translate(calc(100% + #{$connector-width}), -50%);
            display: inline-flex;
            .arrow-head {
                width: 0;
                height: 0;

                border-left: $arrow-width solid $arrow-color;
                border-top: $arrow-height solid transparent;
                border-bottom: $arrow-height solid transparent;

                &:after {
                    position: absolute;
                    content: '';
                    background: $arrow-connector-color;
                    width: $connector-width;
                    height: $connector-height;
                    left: 0%;
                    top: 50%;
                    transform: translate(-100%, -50%);
                }
            }
        }
    }
}

.main-time-bar {
    width: 100%;
    max-width: 75%;
    min-width: 42rem;
    height: 4rem;
    margin: 1rem 4rem 0 0;
    border-radius: 10px;
}

.clocks {
    font-weight: bolder;
    height: 25rem;
    position: relative;
    & > div {
        position: absolute;
        display: inline-flex;
        .connector {

        }
        .total-time-wrapper {
            border: 1px solid gray;
            border-radius: 5px;
            padding: 0.25rem 0.5rem;
            background: white;
            width: max-content;
            margin: 50% 0 0 0;
            transform: translateY(-50%);
        }
        .times {
            width: min-content;

            .timer {
                display: inline-flex;
                margin: 0.25rem;
                .number-wrapper {
                    flex: 1;
                    background: dimgray;
                    padding: 0.25rem 0.75rem;
                    color: white;
                    width: max-content;
                }
                .time-wrapper {
                    flex: 1;
                    margin: 0 0 0 0.5rem;
                    border: 1px solid gray;
                    border-radius: 5px;
                    padding: 0.25rem 0.5rem;
                    background: white;
                    width: max-content;

                }
            }
        }
        .title-wrapper {
            position: absolute;
            top: auto;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 100%);
            font-size: 1.5rem;
            font-weight: 400;

        }
    }

    $connector-vertical-offset: 0.25rem;
    $connector-radius: 15px;

    $quality-top: 17.5rem;
    $quality-left: 10%;
    $performance-top: 10rem;
    $performance-left: 35%;
    $availability-top: 2.5rem;
    $availability-left: 60%;

    @mixin connector {
        border: 1px solid gray;
        left: 44%;
        transform: translateY(calc(-100% + #{$connector-vertical-offset}));
        /* right: auto; */
        position: absolute;
        .connector-circle {
            width: $connector-radius;
            height: $connector-radius;
            border: 1px solid gray;
            position: absolute;
            left: 0;
            transform: translate(-50%, -50%);
            background: white;
            border-radius: 50%;
        }
    }
    .quality {
        top: $quality-top;
        left: $quality-left;
        .connector {
            @include connector;
            height: calc(#{$quality-top} + #{$connector-vertical-offset});
        }
    }
    .performance {
        top: $performance-top;
        left: $performance-left;
        .connector {
            @include connector;
            height: calc(#{$performance-top} + #{$connector-vertical-offset});
        }
    }
    .availability {
        top: $availability-top;
        left: $availability-left;
        .connector {
            @include connector;
            height: calc(#{$availability-top} + #{$connector-vertical-offset});
        }
    }
}

.oee-graph-panel-wrapper {
    max-width: 25%;
    min-width: 24rem;
    border: 1px solid gray;
    background: white;
    border-radius: 15px;
    padding: 1rem;
    width: min-content;

    & > div {
        margin: 0.5rem auto;
        width: 100%;
    }

    .oee-values {
        display: inline-flex;
        text-align: center;
        & > div {
            padding: 0.75rem;
            margin: 0 auto;
            .title {
                font-weight: bolder;
                white-space: nowrap;
            }
            .value-wrapper {
                background: whitesmoke;
                padding: 1.5rem 1rem;
                line-height: 2.5rem;
                border-radius: 0.5rem;
                .value {
                    font-size: 4rem;
                    font-weight: bold;
                }
            }
        }
        .objective-oee .value-wrapper {
            color:#5F9356 ;
        }
        .real-oee .value-wrapper {
            color: red;
        }
    }
    .lost-units {
        & > div {
            display: inline-block;
            width: 100%;
            font-weight: bolder;
            .title {
                padding: 0.25rem;
                float: left;
                font-size: 0.8rem;
            }
            .values {
                float: right;
                display: inline-flex;
                .percentage-wrapper {
                    background: whitesmoke;
                    padding: 0.15rem 0.35rem;
                    margin: 0 0.5rem 0 0;
                    border-radius: 5px;
                    width: min-content;
                }
                .value-wrapper {
                    display: inline-flex;
                    background: whitesmoke;
                    padding: 0.15rem 0.35rem;

                    border-radius: 5px;
                    .unit {
                        margin-left: 0.5rem;
                    }
                }
            }
        }
        .availability .values .percentage-wrapper,
        .availability .values .value-wrapper {
            background: #F2D4CC;
        }
        .performance .values .percentage-wrapper,
        .performance .values .value-wrapper {
            background: #F8E9D4;
        }
        .quality .values .percentage-wrapper,
        .quality .values .value-wrapper {
            background: #D9E9F9;
        }


    }
    .total-lost-units {
        display: inline-flex;
        font-weight: bolder;
        & > div {
            align-self: center;
        }
        .value {
            margin: 0 0 0 1rem;
            font-size: 1.6rem;
        }
        .unit {
            margin: 0 0 0 0.25rem;
            font-size: 0.8rem;
        }
    }
    .losses-breakdown {
        & > .title-wrapper{
            background: #58595B;
            border-radius: 5px;
            text-align: center;
            .title {
                font-weight: bolder ;
                color: white;
            }
        }
        .losses {
            .loss {
                display: inline-flex;

                margin: 0.25rem 0;
                .number-wrapper {
                    flex: 0;
                    background: dimgray;
                    padding: 0.12rem 0.5rem;
                    margin: 0.25rem 0.25rem;
                    color: white;
                    width: max-content;
                }
                .time-wrapper {
                    flex: 1;
                    margin: 0 0 0 0.5rem;
                    border: 1px solid gray;
                    border-radius: 5px;
                    padding: 0.25rem 0.5rem;
                    background: white;
                    width: 85px;
                    .time {
                        max-width: 10ch;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
                .title-wrapper {
                    position: relative;
                    .title {
                        white-space: nowrap;
                        font-weight: bolder;
                        font-size: 0.85rem;
                        line-height: 2.2rem;
                    }
                }
            }
        }
    }
}

.time {
    white-space: nowrap;
}



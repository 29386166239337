$uma-blue: #009EFB;
#priority-matrix-app {
    margin: 0 1rem;
    padding: 1.25rem;
    background: white;
    border-radius: 5px;

    @mixin section-titles {
        font-size: 1.4rem;
        font-weight: 500;
    }
    @mixin menu {
        float: right;
        width: max-content;
        position: relative;
        .menu-icon {}
        &:hover .menu-icon {
            visibility: hidden;
        }
        .menu {
            display: none;
            position: absolute;
            z-index: 10;
            background: white;
            //padding: 0.5rem;
            border-radius:  5px;
            box-shadow: 0 0 10px #A2A2A2;
            right: 0.5rem;
            top: 0.5rem;
            .menu-option {
                padding: 0.25rem 0.5rem;
                white-space: nowrap;
                font-size: 0.95rem;
                border-radius: 5px;
                &:hover {
                    background: #F6F6F6;
                    color: gray;
                    cursor: pointer;
                }
            }
        }
        &:hover .menu {
            display: block;
        }
    }
    .priority-matrix-wrapper {
        & > div {
            margin: 1rem 0;
        }

        .header-wrapper {
            .header-line {
                display: inline-flex;
                width: 100%;
                .title {
                    @include section-titles;
                    margin: 0 auto 0 0;
                }
                .buttons {
                    display: inline-flex;
                    float: right;
                    .button {
                        margin: 0 0.25rem;
                    }
                }
            }
            .priority-matrix-selector-wrapper {
                .priority-matrix-selector{
                    .custom-select {
                        vertical-align: middle;
                    }
                    .priority-matrix-action-buttons{
                        width: max-content;
                        display: inline;
                        button{
                            &:hover {
                                background: darkgray;
                                border: 1px solid gray;
                            }
                        }
                        svg {
                            margin: 0;
                        }
                    }
                }
            }
        }
        .relations-wrapper {
            .relations-header {
                display: inline-flex;
                width: 100%;
                .title {
                    @include section-titles;
                    margin: 0 0.5rem 0 0;
                }
                button {
                    &:hover {
                        background: darkgray;
                        border: 1px solid gray;
                    }
                }
            }

            .relation-line-wrapper {
                display: inline-flex;
                width: 100%;

                & > div {
                    margin: 0.25rem;
                }

                .reference-group-selector {
                    min-width: 10%;
                    width: 10%;
                    & > select {
                        max-width: 100%;
                        width: 100%;
                    }
                }
                .reference-selector {
                    min-width: 10%;
                    width: 10%;
                    & > select {
                        max-width: 100%;
                        width: 100%;
                    }
                }
                .reference-list-wrapper {
                    border: 1px solid gray;
                    border-radius: 5px;
                    display: inline-flex;
                    min-width: 50%;
                    max-width: 75%;

                    .reference-list {
                        display: flex;
                        flex-wrap: wrap;

                        overflow: auto;
                        max-height: 7rem;

                        .reference-pill-wrapper {

                            background: #e5e5e5;
                            border-radius: 5px;
                            width: max-content;
                            height: max-content;
                            display: inline-flex;
                            margin: 0.125rem;

                            .remove-reference-pill-button {
                                padding: 0.125rem;
                            }
                            .title-wrapper {
                                padding: 0.125rem;
                                .title {
                                    white-space: nowrap;
                                    max-width: 30ch;
                                    text-overflow: ellipsis;
                                    overflow: hidden;

                                }
                            }
                        }
                    }
                }
            }


        }
        .operations-wrapper {
            .operations-header {
                display: inline-flex;
                width: 100%;
                .title {
                    @include section-titles;
                    margin: 0 0.5rem 0 0;
                }
                button {
                    &:hover {
                        background: darkgray;
                        border: 1px solid gray;
                    }
                }
            }
            .machine-selector-wrapper {

                ul, li {
                    list-style: none;
                }

                display: inline-flex;
                width: 100%;
                & > div {
                    border: 1px solid gray;
                    margin: 0.25rem;
                }
                .factory-structure-selector-wrapper {
                    max-width: 25%;
                    min-width: 16%;
                    padding: 0.25rem;
                    width: max-content;

                    .factory-structure-selector {

                        .production-line-select-wrapper {
                            width: 100%;
                        }

                        .button-title-wrapper {
                            max-width: max-content;
                            background: white;
                            padding: 0 0 0 0.25rem;
                            border-radius: 5px;
                            &:hover {
                                background: #E7F4F9;
                            }
                        }
                        ul {
                            padding: 0 0 0 1rem;
                        }
                        li {
                            margin: 0.25rem 0;
                        }

                        .selector-title-wrapper {
                            position: relative;
                            display: inline-flex;
                            font-size: 0.95rem;
                            &:before {
                                content: "";
                                height: 50%;
                                width: 0.5rem;
                                position: absolute;
                                transform: translateX(calc(-100% - 0.25rem));
                                border-bottom: 1px dashed black;
                                border-left: 1px dashed black;
                            }
                            button {
                                padding: 0;
                                margin: 0.125rem 0.125rem 0.125rem 0.25rem;
                                border: none;
                                background: none;
                                color: black;
                                &:hover {
                                    background: $uma-blue;
                                    color: white;
                                }
                                svg {
                                    margin: 0 0.125rem;
                                }
                            }
                        }

                        .factory-structure-tree {
                            .production-line-selectors-list {
                                .production-line-selector-wrapper {
                                    .station-selectors-list {
                                        .station-selector-wrapper {
                                            .machine-selectors-list {
                                                .machine-selector {

                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .operations-wrapper {
                    min-width: 75%;
                    width: 100%;
                    max-width: 100%;
                    padding: 0.25rem;
                    overflow: auto;
                    .operations {
                        display: flex;
                        .operation {
                            min-width: 12rem;
                            margin: 0.25rem;
                            &-header {
                                font-size: 1rem;
                                text-align: center;
                                background: lightgray;
                                padding: 0.25rem;

                                .title-wrapper {
                                    display: inline-flex;
                                    width: 100%;
                                    .title {
                                        margin: auto;
                                    }
                                }

                                .menu-wrapper {
                                    @include menu;
                                }
                            }
                            &-board {
                                border: 3px solid lightgray;
                                padding: 0.5rem;
                            }

                            &-machine-wrapper {
                                font-size: 0.9rem;

                                &:first-child {
                                    margin-top: 0;
                                }
                                &:last-child {
                                    margin-bottom: 0;
                                }

                                background: #F6F6F6;
                                margin: 0.25rem 0;
                                border-radius: 5px;
                                padding: 0.25rem;
                                display: flex;
                                .title-wrapper {
                                    margin: 0 auto 0 0;
                                }
                                .menu-wrapper {
                                    @include menu;


                                    float: right;
                                    margin: 0 0 0 0.25rem;
                                    svg {
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}

.modal-line.form-row {
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap;
    & > fieldset {
        margin: 0.5rem 1rem 0.5rem 0;
    }
}

.input-info {
    color: gray;
    font-size: 0.9rem;
}

.input-append-resize {
    padding: 0.375rem 0.25rem;
    max-width: 5rem;
}
select.input-append-resize {
    max-width: 7.5rem;
}

.operation-edit-body {
    .nominal-value {
        vertical-align: baseline !important;
        .input-group > input {
            max-width: 10rem;
        }
        .input-group-append > input {
            width: 5rem;
            padding: 0.375rem 0.25rem;
            text-align: center;

        }
    }

    #reference-grouping-units-table-wrapper {
        margin-top: 0.5rem;
        #reference-grouping-units-table {

            max-height: 35rem;
            overflow: auto;
            display: block;

            tbody {
                & > .reference-grouping-unit-row {
                    //display: inline-flex;
                    //gap: 1rem;
                    //align-items: flex-start;

                    & > * {
                        padding-top: 0;
                    }

                    & > .reference-name-row {
                        width: 15rem ;
                    }
                    & > .nominal-value-row {
                        vertical-align: baseline;
                    }

                    .packaging-select-append {
                        padding: 0.375rem !important;
                    }
                }
            }
        }
    }
}

.operation-group-preview-body {
    font-size: 0.95rem;
    .operation-group-name{
        margin: 0.5rem 0;
        font-size: 1rem;
        &-text {
        }
        &-value {
            font-size: 1.2rem;
            font-weight: 400;
            margin-left: 0.5rem;
        }
    }
    .operations-preview-table {
        &-title {
            font-size: 1.25rem;
            font-weight: 500;
        }
    }

    .machines-preview-collapse {
        padding: 0 0 0 0.5rem;
    }
}